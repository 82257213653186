const util = require("./util");
const profiles = require("./profiles");
const session = require("./session");

function getItensByProfile() {
  const dashboard = {
    name: util.translate("dashboard"),
    url: "/dashboard",
    icon: "cui-dashboard",
  };

  const workersReport = {
    name: util.translate("professionals"),
    url: "/Workers/report",
    icon: "cui-people",
  };

  const activeReport = {
    name: util.translate("asset"),
    url: "/Actives/report",
    icon: "fa fa-cog",
  };

  const holding = {
    name: util.translate("holding"),
    url: "/clientes/relatorio",
    icon: "fa fa-building",
  };

  const companies = {
    name: util.translate("developments"),
    url: "/empresas/relatorio",
    icon: "cui-briefcase",
  };

  const companiesRegister = {
    name: util.translate("development"),
    url: "/empresas/cadastro",
    icon: "cui-briefcase",
  };

  // const contracted = {
  //   name: util.translate("contracted"),
  //   url: "/contratadas/relatorio",
  //   icon: "cui-briefcase",
  // };

  const contracteds = {
    name: util.translate("contracted"),
    url: "/contracteds/relatorio",
    icon: "cui-briefcase",
  };

  const operationalUnit = {
    name: util.translate("operationUnit"),
    url: "/uo",
    icon: "fa fa-bank",
  };

  const plant = {
    name: util.translate("typePerformance"),
    url: "/empreendimentos/relatorio",
    icon: "fa fa-building-o",
  };

  const job = {
    name: util.translate("positions"),
    url: "/jobs",
    icon: "fa fa-sitemap",
  };

  const docsWorker = {
    name: util.translate("professionalDocs"),
    url: "/documentos",
    icon: "cui-file",
  };

  const docsActive = {
    name: util.translate("activeDocs"),
    url: "/documentosAtivos",
    icon: "cui-file",
  };

  const supervision = {
    name: util.translate("supervisions"),
    url: "/supervisoes",
    icon: "cui-magnifying-glass",
  };

  const accessReport = {
    name: util.translate("report"),
    url: "/accessReport",
    icon: "cui-inbox",
  };

  const generalReport = {
    name: util.translate("generalReport"),
    url: "/relatorio",
    icon: "cui-inbox",
  };

  const onSiteQrCodes = {
    name: util.translate("onSiteQRCodes"),
    url: "/qrCode",
    icon: "fa fa-qrcode",
  };
  const smartBadges = {
    name: util.translate("smartBadges"),
    url: "/SmartBadges/Map",
    icon: "fa fa-globe",
  };
  const tracking = {
    name: "Rastreamento",
    url: "/SmartBadges/Tracking",
    icon: "fa fa-map-pin",
  };

  const typeActive = {
    name: util.translate("assetType"),
    url: "/tipoAtivo",
    icon: "fa fa-cogs",
  };

  const user = {
    name: util.translate("users"),
    url: "/usuarios",
    icon: "fa fa-user",
  };

  // const backup = {
  //   name: 'Backup DB',
  //   url: '/bkp_db',
  //   icon: 'fa fa-database'
  // };

  const itemsAdmin = [
    dashboard,
    workersReport,
    holding,
    companies,
    contracteds,
    operationalUnit,
    plant,
    job,
    docsWorker,
    docsActive,
    supervision,
    accessReport,
    generalReport,
    onSiteQrCodes,
    smartBadges,
    tracking,
    activeReport,
    typeActive,
    {
      divider: true,
      class: "m-2",
    },
    // notification,
    {
      divider: true,
      class: "m-2",
    },
    // backup,
  ];

  const itemsClient = [
    dashboard,
    workersReport,
    companies,
    contracteds,
    operationalUnit,
    plant,
    job,
    docsWorker,
    docsActive,
    supervision,
    generalReport,
    smartBadges,
    tracking,
    activeReport,
    typeActive,
    {
      divider: true,
      class: "m-2",
    },
  ];

  const itemsClientAdmin = [
    dashboard,
    workersReport,
    companies,
    contracteds,
    operationalUnit,
    plant,
    job,
    docsWorker,
    docsActive,
    supervision,
    accessReport,
    generalReport,
    onSiteQrCodes,
    activeReport,
    smartBadges,
    tracking,
    typeActive,
    {
      divider: true,
      class: "m-2",
    },
    user,
  ];

  const itemsClientWorker = [
    dashboard,
    workersReport,
    companies,
    contracteds,
    operationalUnit,
    plant,
    job,
    docsWorker,
    supervision,
    generalReport,
    smartBadges,
    tracking,
    {
      divider: true,
      class: "m-2",
    },
  ];

  const itemsClientActive = [
    dashboard,
    companies,
    contracteds,
    operationalUnit,
    plant,
    docsActive,
    supervision,
    generalReport,
    activeReport,
    typeActive,
    smartBadges,
    tracking,
    {
      divider: true,
      class: "m-2",
    },
  ];

  const itemsCompanie = [
    dashboard,
    workersReport,
    companiesRegister,
    contracteds,
    operationalUnit,
    plant,
    job,
    docsWorker,
    docsActive,
    supervision,
    smartBadges,
    tracking,
    generalReport,
    activeReport,
    typeActive,
    {
      divider: true,
      class: "m-2",
    },
  ];

  const itemsCompanieAdmin = [
    dashboard,
    workersReport,
    companiesRegister,
    contracteds,
    operationalUnit,
    plant,
    job,
    docsWorker,
    docsActive,
    supervision,
    accessReport,
    generalReport,
    onSiteQrCodes,
    smartBadges,
    tracking,
    activeReport,
    typeActive,
    {
      divider: true,
      class: "m-2",
    },
  ];

  const itemsCompanieWorker = [
    dashboard,
    workersReport,
    companiesRegister,
    contracteds,
    operationalUnit,
    plant,
    job,
    docsWorker,
    supervision,
    generalReport,
    smartBadges,
    tracking,
    {
      divider: true,
      class: "m-2",
    },
  ];

  const itemsCompanieActive = [
    dashboard,
    companiesRegister,
    contracteds,
    operationalUnit,
    plant,
    docsActive,
    supervision,
    generalReport,
    activeReport,
    typeActive,
    smartBadges,
    tracking,
    {
      divider: true,
      class: "m-2",
    },
  ];

  const itemsSupervisor = [
    dashboard,
    workersReport,
    job,
    docsWorker,
    docsActive,
    supervision,
    generalReport,
    activeReport,
    typeActive,
    smartBadges,
    tracking,
    {
      divider: true,
      class: "m-2",
    },
  ];

  // validação para o icones de smart badges
  const userConfig = session.getUserConfigs();
  if (!userConfig) return []; // aqui eu validando se realmente tem o user

  const retItems = { items: [] };

  const { smartBadge } = userConfig;

  const userConfigs = session.getUserConfigs();
  if (userConfigs.loginSso !== 1) {
    itemsAdmin.push(user);
    itemsCompanieAdmin.push(user);
    itemsClientAdmin.push(user);
  }
  /**
   * Retorna a lista de items de menu com base no perfil do usuario logado
   */
  const profile = session.getUserProfile();
  if (profile === profiles.PROFILE_SUPERUSER) {
    retItems.items = itemsAdmin;
  }

  //client - holding
  if (profile === profiles.PROFILE_CLIENT) {
    retItems.items = itemsClientAdmin;
  }

  if (profile === profiles.PROFILE_CLIENT_VIEWER) {
    retItems.items = itemsClient;
  }

  if (
    profile === profiles.PROFILE_CLIENT_WORKER_ADMIN ||
    profile === profiles.PROFILE_CLIENT_WORKER_VIEWER
  ) {
    retItems.items = itemsClientWorker;
  }

  if (profile === profiles.PROFILE_CLIENT_ACTIVE_ADMIN) {
    retItems.items = itemsClientActive;
  }

  if (profile === profiles.PROFILE_CLIENT_ACTIVE_VIEWER) {
    retItems.items = itemsClientActive;
  }

  //companie
  if (profile === profiles.PROFILE_COMPANIE) {
    retItems.items = itemsCompanieAdmin;
  }

  if (profile === profiles.PROFILE_COMPANIE_VIEWER) {
    retItems.items = itemsCompanie;
  }

  if (
    profile === profiles.PROFILE_COMPANIE_WORKER_ADMIN ||
    profile === profiles.PROFILE_COMPANIE_WORKER_VIEWER
  ) {
    retItems.items = itemsCompanieWorker;
  }

  if (
    profile === profiles.PROFILE_COMPANIE_ACTIVE_ADMIN ||
    profile === profiles.PROFILE_COMPANIE_ACTIVE_VIEWER
  ) {
    retItems.items = itemsCompanieActive;
  }

  //supervisor
  if (profile === profiles.PROFILE_SUPERVISOR) {
    retItems.items = itemsSupervisor;
  }

  if (!smartBadge) {
    retItems.items = retItems.items.filter(
      (option) => option.name !== smartBadges.name
    );
    retItems.items = retItems.items.filter(
      (option) => option.name !== tracking.name
    );
  }
  return retItems;
}

export default getItensByProfile;
