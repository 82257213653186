import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Label,
  UncontrolledTooltip,
  InputGroupText,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import Select from "react-select";

import profiles from "../../../util/profiles";
import encryptUtil from "../../../util/encryptUtil";
import session from "../../../util/session";
import util, { getLanguages } from "../../../util/util";

import "ladda/dist/ladda-themeless.min.css";
import "react-toastify/dist/ReactToastify.css";
import { MainLogo } from "../../../components/Logo/MainLogo";

const optionsToast = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

class Login extends Component {
  componentDidMount() {
    sessionStorage.clear();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const userName = localStorage.getItem("userName");
    const password = localStorage.getItem("password");
    this.setState({
      loading_scr: true,
      passwd: password ? encryptUtil.decrypt(password) : "",
      usuario: userName ? encryptUtil.decrypt(userName) : "",
    });

    this.validateBrowser();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  constructor(props) {
    super(props);

    this.forget_pwd = this.forgetPassword.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.signIn = this.signIn.bind(this);

    this.state = {
      loading_scr: false,
      loading_btn: false,
      showBtn: true,
      showForgotPass: true,
      showCheckBox: true,
      showSavePass: true,
      showMsgUsuario: false,
      keep_login:
        localStorage.getItem("keep_login") != null
          ? localStorage.getItem("keep_login")
          : false,
      passwd: "",
      usuario: "",
      width: 0,
      height: 0,
      browserValid: true,
    };
  }
  updateWindowDimensions() {
    this.setState(
      { width: window.innerWidth, height: window.innerHeight },
      () => {}
    );
  }

  changLanguage(language) {
    localStorage.setItem("language", language);
    window.location.reload();
  }

  toggle(name) {
    this.setState({
      [name]: !this.state[name],
      progress: 0.5,
    });
  }

  async validateBrowser() {
    const url = `${process.env.REACT_APP_URI}/validations/browser`;
    try {
      const response = await fetch(url, session.getRequestOptions());
      if (response.status !== 200) {
        this.setState({ browserValid: false });
      }
    } catch (error) {
      console.log(error);
    }
  }

  signIn = async (e) => {
    e.preventDefault();
    const usuario = this.state.usuario;
    const senha = this.state.passwd;
    let token = "";
    let userConfigs = {};
    let logged = false;

    if (!usuario || !senha) {
      toast.error(
        <div>
          {" "}
          <h4>
            {util.translate("authenticationFailed")}
            <br />{" "}
          </h4>
          {util.translate("fillEmailAndPasswordFields")}
        </div>,
        optionsToast
      );
      this.setState({ loading_btn: false });
      return;
    }

    this.toggle("loading_btn");
    let formData = {
      username: usuario,
      password: senha,
    };
    const url = `${process.env.REACT_APP_URI}/user/loginWeb`;

    const resp = await fetch(url, session.getRequestOptions("POST", formData));
    const res = await resp.json();
    if (resp.status !== 200) {
      toast.error(
        <div>
          {" "}
          <h4>
            {util.translate("authenticationFailed")}
            <br />{" "}
          </h4>
          {util.translate(res.token)}
        </div>,
        optionsToast
      );
      this.setState({ loading_btn: false });
    } else {
      const loginModel = res.details;

      if (loginModel.status === util.STATUS_LOGIN_EXPIRE) {
        this.setState({ loading_btn: false });
        this.setState({
          showBtn: false,
          showCheckBox: false,
          showForgotPass: false,
          showSavePass: false,
          showMsgUsuario: true,
        });
        return;
      }
      userConfigs = {
        id: loginModel.id,
        name: loginModel.name,
        email: loginModel.username,
        profile: loginModel.accessLevel,
        status: loginModel.status,
        showPrivacy: loginModel.showPrivacy,
        privacy: loginModel.privacy,
        loginSso: loginModel.login_sso,
        smartBadge: loginModel.smartBadge,
      };
      if (loginModel.clientId) {
        userConfigs.client = loginModel.clientId;
      }

      if (loginModel.companieId) {
        userConfigs.companie = loginModel.companieId;
        userConfigs.companieName = loginModel.companie.name;
      }

      if (loginModel.contractedId) {
        // userConfigs.contracted = loginModel.contractedId;
        if (
          loginModel.contracted &&
          (loginModel.contracted.type === util.TYPE_CONTRACTED_CONTRACTED ||
            loginModel.contracted.type === null)
        ) {
          userConfigs.contractedId = loginModel.contracted.id;
          userConfigs.companieName = loginModel.companie.name;
        }
        if (
          loginModel.contracted &&
          loginModel.contracted.contractedId &&
          loginModel.contracted.type === util.TYPE_CONTRACTED_SUBCONTRACTED
        ) {
          userConfigs.contractedId = loginModel.contracted.contractedId;
          userConfigs.subContractedId = loginModel.contracted.id;
        }
        if (
          loginModel.contracted &&
          loginModel.contracted.contractedId &&
          loginModel.contracted.subContracted &&
          loginModel.contracted.type === util.TYPE_CONTRACTED_SUBSUBCONTRACTED
        ) {
          userConfigs.contractedId =
            loginModel.contracted.subContracted.contractedId;
          userConfigs.subContractedId = loginModel.contracted.contractedId;
          userConfigs.subSubContractedId = loginModel.contracted.id;
        }
        // userConfigs.subSubContracted = loginModel.contracted && loginModel.contracted.contractedId && loginModel.contracted.subContracted ? loginModel.contracted.subContracted.contractedId : null;
        // userConfigs.contractedId = loginModel.contracted ? loginModel.contracted.contractedId : null;
        userConfigs.contracted = loginModel.contracted
          ? loginModel.contracted
          : null;
      }

      if (loginModel.subContractedId) {
        userConfigs.subContracted = loginModel.subContractedId;
      }
      if (loginModel.status) {
        userConfigs.status = loginModel.status;
      }

      if (loginModel.workerId) {
        userConfigs.worker = loginModel.workerId;
      }
      userConfigs.userFile = this.getUserPhoto(loginModel);
      token = res.token;
      logged = true;

      // getLoginSso(userConfigs.loginSso);
    }

    if (logged) {
      await this.persistValues(usuario, senha, token, userConfigs);
      if (
        profiles.isProfileContracted(+userConfigs.profile) ||
        profiles.isProfileSubContracted(+userConfigs.profile)
      ) {
        if (profiles.isProfilesActivesContracteds(+userConfigs.profile)) {
          this.props.history.push("/Actives/report");
        } else {
          this.props.history.push("/Workers/report");
        }
      } else if (+userConfigs.profile === +profiles.PROFILE_WORKER) {
        this.props.history.push({
          pathname: "pessoas/informacoes",
          query: { worker: userConfigs.worker },
        });
      } else {
        this.props.history.push("/dashboard");
      }
    }
  };

  async loginSso() {
    try {
      const url = `${process.env.REACT_APP_URI}/compac/login/sso`;
      const resp = await fetch(url, session.getRequestOptions());
      if (resp) {
        const res = await resp.json();
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * retorna o caminho da foto de perfil do usuario
   * @param {*} user
   */
  getUserPhoto(user) {
    if (user.worker && user.worker.file) return user.worker.file;
    if (user.photo) return user.photo;
    return null;
  }

  async persistValues(usuario, senha, token, userConfigs) {
    session.createSessionToken();

    if (this.state.keep_login) {
      await localStorage.setItem("userName", encryptUtil.encrypt(usuario));
      await localStorage.setItem("password", encryptUtil.encrypt(senha));
    } else {
      await localStorage.removeItem("userName");
      await localStorage.removeItem("password");
    }
    localStorage.setItem("keep_login", this.state.keep_login);
    await sessionStorage.setItem("token", token);
    await sessionStorage.setItem(
      "ttv",
      encryptUtil.encrypt(new Date().getTime().toString())
    );
    await sessionStorage.setItem(
      "userConfig",
      encryptUtil.encrypt(JSON.stringify(userConfigs))
    );
  }

  async forgetPassword(usuario) {
    if (usuario === "") {
      toast.error(
        <div>
          {" "}
          <h4>
            {util.translate("pleaseCompleteUser")}
            <br />{" "}
          </h4>
          {util.translate("itNecessaryInformUser")}
        </div>,
        optionsToast
      );
      return;
    }
    const language = await localStorage.getItem("language");
    const formData = {
      username: usuario,
      language,
    };
    const url = process.env.REACT_APP_URI + "/reset_pwd";
    const resp = await fetch(url, session.getRequestOptions("POST", formData));
    if (resp.status === 200) {
      toast.success(
        <div>
          <h4>
            {util.translate("passwordChangeRequestSent")}
            <br />
          </h4>
          {util.translate("anEmailCreateNewPassword")}
          <br />
          {util.translate("checkYourEmailBox")}
        </div>,
        optionsToast
      );
      this.setState({ usuario: "" });
    } else {
      const res = await resp.json();
      toast.error(
        <div>
          {" "}
          <h4>
            {util.translate("requestFailed")}
            <br />{" "}
          </h4>
          {res.details}
        </div>,
        optionsToast
      );
    }
  }

  render() {
    return (
      <div
        className="app flex-row align-items-center animated fadeIn"
        style={{ backgroundColor: "#080864" }}
      >
        <ToastContainer
          containerId="loginToastId"
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
        />
        <Container>
          <Row
            className="justify-content-center"
            style={{ marginBottom: "2.5em", paddingTop: "-2em" }}
          >
            <Col
              xs="12"
              sm="12"
              md="5"
              lg="5"
              xl="5"
              className="align-content-center"
            >
              <Row className="justify-content-center">
                <MainLogo width="100%" height="100%" />
              </Row>
              <Row className="justify-content-center">
                <h1
                  style={{
                    color: "white",
                    fontWeight: "normal",
                    fontSize:
                      this.state.width > 1024
                        ? "2vw"
                        : this.state.width > 500
                        ? "2.75vw"
                        : "8vw",
                  }}
                >
                  {util.translate("securitySuperfision")}
                </h1>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {!this.state.browserValid && (
              <Col md="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    {util.translate("invalidBrowser")}
                  </span>
                </div>
              </Col>
            )}

            {this.state.browserValid && (
              <Col md="6">
                <CardGroup>
                  <Card className="p-4">
                    <CardBody>
                      <Form style={{ fontSize: "1vmax" }}>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend"></InputGroupAddon>
                          <Input
                            id="userEntry"
                            name="userEntry"
                            type="text"
                            value={this.state.usuario}
                            onChange={(user) =>
                              this.setState({ usuario: user.target.value })
                            }
                            placeholder={util.translate("email")}
                            autoComplete="username"
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Input
                            id="passEntry"
                            name="passEntry"
                            type="password"
                            placeholder={util.translate("password")}
                            autoComplete="current-password"
                            value={this.state.passwd}
                            onChange={(senha) =>
                              this.setState({ passwd: senha.target.value })
                            }
                          />
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText
                              style={{
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                              }}
                            >
                              <i
                                id="explainPassword"
                                className="fa fa-info"
                              ></i>
                              <UncontrolledTooltip
                                style={{
                                  backgroundColor: "rgb(255, 255, 255)",
                                  fontWeight: "bold",
                                }}
                                placement="right"
                                target="explainPassword"
                              >
                                <p style={{ color: "rgb(0, 0, 158)" }}>
                                  {util.translate(
                                    "afterFiveFailedAttemptsUserBlocked"
                                  )}
                                </p>
                              </UncontrolledTooltip>
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        <InputGroup className="mb-3">
                          {this.state.showMsgUsuario && (
                            <div
                              style={{
                                width: "100%",
                                height: "80px",
                                textAlign: "center",
                                backgroundColor: "#D12F0D",
                                borderRadius: "10px",
                              }}
                            >
                              <h4 style={{ color: "#ffffff" }}>
                                {util.translate("yourUserbeenBlocked")}
                              </h4>
                              <p
                                style={{
                                  backgroundColor: "#D12F0D",
                                  borderBottomLeftRadius: "10px",
                                  borderBottomRightRadius: "10px",
                                  color: "#ffffff",
                                }}
                              >
                                {util.translate(
                                  "hisUserBeenInactiveForSixmonths"
                                )}
                                <br />
                                {util.translate("cotactYourSystemAdmUnlokUser")}
                              </p>
                            </div>
                          )}
                          {this.state.showCheckBox && (
                            <Input
                              style={{ marginLeft: "0px" }}
                              type="checkbox"
                              id="saveCredChk"
                              name="saveCredChk"
                              value={this.state.keep_login}
                              defaultChecked={this.state.keep_login}
                              onChange={(keep) =>
                                this.setState({
                                  keep_login: keep.target.checked,
                                })
                              }
                            />
                          )}
                          {this.state.showSavePass && (
                            <Label
                              style={{ marginLeft: "15px", fontSize: "14px" }}
                              check
                              className="form-check-label"
                              htmlFor="checkbox1"
                            >
                              {util.translate("saveCredentials")}
                            </Label>
                          )}
                        </InputGroup>
                        <Row
                          className="justify-content-center"
                          style={{ paddingBottom: "1em" }}
                        >
                          {this.state.showBtn && (
                            <LaddaButton
                              id="enterBtn"
                              name="enterBtn"
                              className="btn btn-ladda"
                              loading={this.state.loading_btn}
                              onClick={this.signIn}
                              data-color="green"
                              data-style={EXPAND_LEFT}
                              style={{
                                width: "100%",
                                height: "40px",
                                backgroundColor: "#0074d8",
                                color: "white",
                                fontWeight: "bolder",
                              }}
                            >
                              {util.translate("getIn")}
                            </LaddaButton>
                          )}
                        </Row>
                        {/** Botão sso ainda en testes por isso vai ficar comentado
                        <Row
                          className="justify-content-center"
                          style={{ paddingBottom: "1em" }}
                        >
                          <LaddaButton
                            id="enterBtnSso"
                            name="enterBtnSso"
                            className="btn btn-ladda"
                            onClick={this.loginSso}
                            style={{
                              width: "100%",
                              height: "40px",
                              backgroundColor: "#0074d8",
                              color: "white",
                              fontWeight: "bolder",
                            }}
                          >
                            Entrar via SSO
                          </LaddaButton>
                        </Row>
                       */}
                        <Row className="justify-content-center">
                          {this.state.showForgotPass && (
                            <Button
                              color="link"
                              className="px-0"
                              onClick={this.forgetPassword.bind(
                                this,
                                this.state.usuario
                              )}
                            >
                              {util.translate("forgotYourPassword")}
                            </Button>
                          )}
                          {!this.state.showMsgUsuario && (
                            <Select
                              id="selectLanguage"
                              name="selectLanguage"
                              options={getLanguages()}
                              clearable={false}
                              value={util.filterSelect(
                                getLanguages(),
                                localStorage.getItem("language")
                              )}
                              isSearchable={false}
                              hideSelectedOptions
                              onChange={(option) =>
                                this.changLanguage(option.value)
                              }
                              styles={{
                                control: () => ({
                                  marginTop: "10px",
                                  marginLeft: "10px",
                                  background: "transparent",
                                }),
                                dropdownIndicator: () => ({
                                  display: "none",
                                }),
                              }}
                            />
                          )}
                        </Row>
                        <Row></Row>
                      </Form>
                    </CardBody>
                  </Card>
                </CardGroup>
                <Row style={{ paddingTop: "1em" }}>
                  <Col xs="6">
                    <span style={{ fontSize: "13px", color: "white" }}>
                      {process.env.REACT_APP_BRANDING === 'viacon' ?
                        'suporte@viaconapp.com.br' :
                        'suporte@datalife.global'
                      }
                    </span>
                  </Col>
                  <Col xs="6" className="text-right">
                    <span style={{ fontSize: "13px", color: "white" }}>
                      {process.env.REACT_APP_VERSION_SYSTEM}
                    </span>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
