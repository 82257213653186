import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Alert,
  Button,
} from "reactstrap";
import Select from "react-select";

import util, { getLanguages, getLoginSso, translate } from "../../util/util";

import session from "../../util/session";
import ModalListNotification from "../../views/Notification/modal/ModalListNotification";
import ModalReadNotification from "../../views/Notification/modal/ModalReadNotification";

import "./styles.css";

const propTypes = {
  notif: PropTypes.bool,
  accnt: PropTypes.bool,
  tasks: PropTypes.bool,
  mssgs: PropTypes.bool,
};
const defaultProps = {
  notif: false,
  accnt: false,
  tasks: false,
  mssgs: false,
};

class DefaultHeaderDropdown extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.controlNotificationModal = this.controlNotificationModal.bind(this);

    this.state = {
      dropdownOpen: false,
      username: "",
      name: "",
      empreendimento: "",
      operacao: "",
      supervisor: "",
      worker_id: "",
      avatar: "assets/img/avatar.png",
      width: 0,
      height: 0,
      openModalListNotification: false,
      openModalReadNotification: false,
      dataModal: "",
      badgeNumber: 0,
      color: "",
      showAlertFields: false,
      msgAlert: "",
      nameCompanie: "",
      showNameCompanie: 0,
    };
  }
  changLanguage(language) {
    localStorage.setItem("language", language);
    window.location.reload();
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  componentWillMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    try {
      const userConfigs = session.getUserConfigs();
      if (userConfigs) {
        this.setState({
          username: userConfigs.email,
          name: userConfigs.name,
        });

        if (userConfigs.contractedId || userConfigs.contractedId) {
          this.setState({
            showNameCompanie: 1,
            nameCompanie: userConfigs.companieName,
          });
        }

        if (userConfigs.userFile && userConfigs.userFile !== "") {
          this.setState({
            avatar: process.env.REACT_APP_URI + userConfigs.userFile,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidMount() {
    // await this.badgeNotifications()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  async controlNotificationModal(row) {
    await this.badgeNotifications();
    if (this.state.openModalListNotification) {
      this.setState({
        openModalListNotification: false,
        openModalReadNotification: true,
        dataModal: row,
      });
    } else {
      this.setState({
        openModalListNotification: true,
        openModalReadNotification: false,
      });
    }
  }

  async badgeNotifications() {
    const url = `${process.env.REACT_APP_URI}/notification/badgeNotification`;
    const resp = await fetch(url, session.getRequestOptions());
    const res = await resp.json();

    this.setState({ badgeNumber: +res.count });
  }

  async controlBadgeNotification() {
    await this.badgeNotifications();
    this.setState({
      openModalListNotification: false,
      openModalReadNotification: false,
    });
  }

  showAlertMessage(msg, alertType = "success") {
    this.setState({ msgAlert: msg, color: alertType });
    this.setState({ showAlertFields: true });
    setTimeout(() => {
      this.setState({ showAlertFields: false });
    }, 10000);
  }

  // async executeForcedPoints() {
  //   const url = `${process.env.REACT_APP_URI}/registerAccess/executeForced`;
  //   const resp = await fetch(url, session.getRequestOptions());
  //   const message =
  //     resp.status === 200 ? "Executado com sucesso" : "Erro na execução";
  //   this.showAlertMessage(message);
  // }

  async executeRegisterAccess() {
    const url = `${process.env.REACT_APP_URI}/registerAccess/executeRegisterAccess`;
    const resp = await fetch(url, session.getRequestOptions());
    const message = resp.status === 200 ? "Executado com sucesso" : "SEM DADOS";
    this.showAlertMessage(message);
  }

  dropAccnt() {
    return (
      <>
        <Alert
          color={this.state.color}
          className="text-center"
          isOpen={this.state.showAlertFields}
        >
          {this.state.msgAlert}
        </Alert>
        <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <Row style={{ paddingRight: "40px" }}>
            {process.env.REACT_APP_PRODUCTION !== "true" && (
              <Button
                id="executeForcedPoint"
                color="danger"
                onClick={() => this.executeRegisterAccess()}
                style={{ height: "53px" }}
              >
                Registro de Acesso
              </Button>
            )}
            <div style={{ marginRight: "20px" }}>
              <Select
                id="selectLanguage"
                name="selectLanguage"
                options={getLanguages()}
                clearable={false}
                hideSelectedOptions
                isSearchable={false}
                value={util.filterSelect(
                  getLanguages(),
                  localStorage.getItem("language")
                )}
                onChange={(option) => this.changLanguage(option.value)}
                styles={{
                  control: () => ({
                    marginTop: "10px",
                    background: "transparent",
                  }),
                  dropdownIndicator: () => ({
                    display: "none",
                  }),
                }}
              />
            </div>
            {this.state.width > 650 && (
              <div style={{ marginTop: "5px" }}>
                {this.state.showNameCompanie === 0 ? (
                  <span className="labelName">{this.state.name}</span>
                ) : (
                  <span className="labelName">
                    {`${translate("development:")}  ${this.state.nameCompanie}`}
                  </span>
                )}

                <br></br>
                <span className="labelName">{this.state.username}</span>
              </div>
            )}
            <DropdownToggle nav>
              {/* {(this.state.badgeNumber !== 0)  &&
                <div className='badgeNotication'>
                  <div className='numberBadge'>
                    <span>{this.state.badgeNumber}</span>
                  </div>
                </div>
              } */}
              <img
                id="imgRound"
                name="imgRound"
                alt="avatar"
                src={
                  this.state.avatar === null
                    ? "assets/img/avatar.png"
                    : `${this.state.avatar}?${new Date().getTime()}`
                }
                className="rounded float-right"
                style={{
                  maxWidth: "45px",
                  maxHeight: "45px",
                  objectFit: "cover",
                  objectPosition: "top",
                  marginTop: "5px",
                }}
              />
            </DropdownToggle>
            <ModalListNotification
              openModal={this.state.openModalListNotification}
              closeModal={() => this.controlBadgeNotification()}
              controlNotificationModal={this.controlNotificationModal}
            />

            <ModalReadNotification
              openModal={this.state.openModalReadNotification}
              closeModal={() => this.controlBadgeNotification()}
              controlNotificationModal={this.controlNotificationModal}
              row={this.state.dataModal}
              updateBadge={() => this.badgeNotifications()}
            />
          </Row>

          <DropdownMenu left>
            {getLoginSso() !== "true" && (
              <>
                <DropdownItem
                  id="changePwdBtn"
                  name="changePwdBtn"
                  href="#/usuarios/alterarSenha"
                >
                  <i className="fa fa-key"></i>{" "}
                  {util.translate("changePassword")}
                </DropdownItem>
                <DropdownItem
                  id="changeDatasBtn"
                  name="changeDatasBtn"
                  href="#/usuarios/alterarDados"
                >
                  <i className="fa fa-edit"></i> {util.translate("changeData")}
                </DropdownItem>
              </>
            )}
            <DropdownItem
              id="logOutBtn"
              name="logOutBtn"
              href="#/login"
              onClick={() => {
                this.signOut();
              }}
            >
              <i className="fa fa-lock"></i> {util.translate("logout")}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </>
    );
  }

  signOut() {
    sessionStorage.clear();
  }

  render() {
    return this.dropAccnt();
  }
}

DefaultHeaderDropdown.propTypes = propTypes;
DefaultHeaderDropdown.defaultProps = defaultProps;

export default DefaultHeaderDropdown;
